import React, { useEffect, useState } from "react";
import "./App.css";
import twitterLogo from "./assets/twitter-logo.svg";
import logoPic from './assets/logo.png';
import opensea from './assets/opensea.png';
import twitter from './assets/x.png';
import cg from './assets/cg.png';
import discrd from './assets/discrd.png';
import ig from './assets/ig.png';
import tg from './assets/tg.png';
import tiktok from './assets/tiktok.png';
import yt from './assets/yt.png';
import ticker from './assets/ticker.png';
import tickerMob from './assets/ticker-mob.png';
import nft1 from './assets/Group 42615.png';
import nft2 from './assets/Group 42616.png';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import nftMob from './assets/nftMob.png';

// Constants
const TWITTER_HANDLE = "_buildspace";
const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;

const App = () => {

	const [_navbarOpen, set_navbarOpen] = useState(0)

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}

	const checkIfWalletIsConnected = async () => {
		try {
			const { solana } = window
			if (solana) {
				if (solana.isPhantom) {
					console.log('Phantom wallet found')
				}
			} else {
				alert("Solana object not found! Get a Phantom Wallet");
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const onLoad = async () => {
			await checkIfWalletIsConnected()
		}
		window.addEventListener('load', onLoad)
		return () => window.removeEventListener('load', onLoad)
	}, [])

	return (
		<div className="App">
			<div className="bg">

				{_navbarOpen < 1 ?
					(<div class="miniBarMain">
						<div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

						<div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
					</div>) :
					(<div class="miniBarMain">
						<div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

						<div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
					</div>)}

				{_navbarOpen > 0 ?
					(<div class="littleNav">

						<div class="connect2Div">
							Back to website
						</div>

						<div class="connect2">
							<button class="button-71">Connect Wallet</button>
						</div>

						<div class="socialIconMain">

							<div class="socialIcon">
								<img src={twitter} />
								<img src={tg} />
								<img src={discrd} />
								<img src={ig} />
								<img src={tiktok} />
								<img src={yt} />
								<img src={cg} />

							</div>
						</div>
					</div>) : null}

				<div class="headers">

					<div class="h1">

						<div class="socialIconMain">

							<div class="socialIcon">
								<img src={twitter} />
								<img src={tg} />
								<img src={discrd} />
								<img src={ig} />
								<img src={tiktok} />
								<img src={yt} />
								<img src={cg} />

							</div>
						</div>

						<div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

						<div class="right">

							<div class="connect2Div">
								Back to website
							</div>

							<div class="connect2">
								<button class="button-71">Connect Wallet</button>
							</div>

						</div>

					</div>

				</div>

				<div className="contentMain">
					<div className="title">THE <br /> FAMILY TOKEN</div>

					<img className="ticker" src={ticker} />
					<img className="tickerMob" src={tickerMob} />

					<div className="mintArea">
						<div className="mintSection">
							<div className="amount">Select Amount</div>
							<div class="minting_count_button">

								<div class="center">

									<button class="btnfos-0-1" type="submit">-</button>

								</div>

								<div class="center">
									<div class="nftminter2">1</div>
								</div>


								<div class="center">
									<button class="btnfos-0-2" type="submit">+</button>

								</div>
							</div>
							<div className="amount"><center>4 ETH + Gas</center></div>

							<button className="mintBtn">Mint Family</button>

						</div>

					</div>

					<div className="nftPics">
						<img className="nft1" src={nft1} />

						<img className="nft2" src={nft2} />
					</div>

					<div className="nftPics-mob">
						<img className="nft1" src={nftMob} />
					</div>
					
				</div>

				<div className="totalSupply">
					<div className="t1">Total NFTs <br /> Minted</div>
					<div className="t2">94/10,000</div>
				</div>

				{/*<div className="container">
					<div className="header-container">
						<p className="header">🍭 Candy Drop</p>
						<p className="sub-text">NFT drop machine with fair mint</p>
					</div>
					<div className="footer-container">
						<img
							alt="Twitter Logo"
							className="twitter-logo"
							src={twitterLogo}
						/>
						<a
							className="footer-text"
							href={TWITTER_LINK}
							target="_blank"
							rel="noreferrer"
						>{`Adapted from @${TWITTER_HANDLE}`}</a>
					</div>
				</div>*/}
			</div>
		</div>
	);
};

export default App;
